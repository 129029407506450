import React from 'react';
import Col from '../../ui/Grid/Col';
import Container from '../../ui/Grid/Container';
import Row from '../../ui/Grid/Row';
import Headline from '../../ui/Headline';
import TrainingPartnerTeaserEntry from './components/TrainingPartnerTeaserEntry';
import * as styles from './styles.module.scss';
import ContentSlider from '../../ui/ContentSlider';
import { Settings } from 'react-slick';

type TrainingPartnerTeaserProps = {
  sectionHeadline?: string;
  trainingPartnerTeaserEntries: Array<React.ComponentProps<typeof TrainingPartnerTeaserEntry>>;
  teaserType?: boolean;
};

const TrainingPartnerTeaser: React.FC<TrainingPartnerTeaserProps> = ({
  trainingPartnerTeaserEntries,
  sectionHeadline,
  teaserType,
}) => {
  const settings: Settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    cennter: true,
    centerPadding: '15px',
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    dots: true,
    responsive: [
      {
        breakpoint: 767,
      },
      {
        breakpoint: 10000,
        settings: 'unslick',
      },
    ],
  };

  return (
    <Container additionalClass={styles.container}>
      {teaserType ? (
        <>
          {sectionHeadline && (
            <div className="text-center mb-50 desktop:mb-100 w-2/3 mx-auto">
              <Headline level={2} color="Black">
                {sectionHeadline}
              </Headline>
            </div>
          )}
        </>
      ) : (
        <>
          {sectionHeadline && (
            <Headline level={2} additionalClass={styles.hl} color="Black">
              {sectionHeadline}
            </Headline>
          )}
        </>
      )}
      {teaserType ? (
        <Row>
          {trainingPartnerTeaserEntries.map((entry, idx) => (
            <Col mobile={12} tablet={6} desktop={6} key={idx}>
              <TrainingPartnerTeaserEntry {...entry} teaserType={teaserType} />
            </Col>
          ))}
        </Row>
      ) : (
        <ContentSlider additionalSettings={settings} additionalClass={styles.sliderWrapper}>
          {trainingPartnerTeaserEntries.map((teamMember, idx) => (
            <TrainingPartnerTeaserEntry {...teamMember} key={idx} teaserType={teaserType} />
          ))}
        </ContentSlider>
      )}
    </Container>
  );
};

export default TrainingPartnerTeaser;
