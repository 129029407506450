import React from 'react';
import { Document } from '@contentful/rich-text-types';
import Headline from '../../../../ui/Headline';
import RichText from '../../../../ui/RichText';
import { IImage } from '../../../../ui/Image/types';
import Image from '../../../../ui/Image';
import * as styles from './styles.module.scss';

type TrainingPartnerTeaserEntryProps = {
  headline: string;
  image: IImage;
  text: Document;
  teaserType?: boolean;
};

const TrainingPartnerTeaserEntry: React.FC<TrainingPartnerTeaserEntryProps> = ({
  headline,
  text,
  image,
  teaserType,
}) => {
  return (
    <>
      {teaserType ? (
        <div className={styles.entryContainer}>
          <Image {...image} additionalClass={styles.img} />
          <div className={styles.textContainer}>
            <Headline level={3} additionalClass="text-28 pt-25" color="Black">
              {headline}
            </Headline>
            {text && (
              <div className={styles.textWrapper}>
                <RichText content={text} additionalClass={styles.text} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.teamMemberWrapper}>
          <div className={styles.teamMemberOverlay}>
            <p className={styles.teamMemberName}>{headline}</p>
            {text && <RichText content={text} additionalClass={styles.text} />}
          </div>
          <Image {...image} additionalClass={styles.img} />
        </div>
      )}
    </>
  );
};

export default TrainingPartnerTeaserEntry;
